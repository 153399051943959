import React, {useState, useEffect} from "react";
import loadable from "@loadable/component";
import { Container, Row, Col } from "react-bootstrap";
import LayoutTwo from "../components/layoutTwo";
import Seo from "../components/seo"
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));

function  SaleskisoskYasPark() {
    const [iframeAttributes, setIframeAttributes] = useState({
        width: '100%',
        height: '600px',
        src: 'https://saleskiosk.aldar.com/YasParkViews/?state=yas_island&lang=en&vr=VR01&color=dark&theme=light',
      });
    // useEffect(() => {
    //     setIframeAttributes(updatedAttributes);
    // }, []);
    return (
        <LayoutTwo>
            <div className="layout-padding-top">
                <BreadcrumbModule pagename="Saleskisosk Yas Park Views" />

                <Container>
                    <Row>
                        <Col>
                            <iframe {...iframeAttributes} title="Responsive Iframe" />;
                        </Col>
                    </Row>
                </Container>

            </div>
        </LayoutTwo>
    )
}

export const Head = () => <Seo title="Yas Park Views" />
export default SaleskisoskYasPark